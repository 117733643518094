import React, { useState, useEffect, Fragment } from 'react'
import { Block, Card, Grid, Filter, Button } from '@components'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const FilteredList = ({ defaultQueries = new Map([]), location }) => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsPost {
        nodes {
          title
          slug
          path
          originalId
          categories {
            title
            slug
            id
          }
          meta {
            firstPublishedAt(formatString: "DD MMMM YYYY")
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "380", w: "330", fit: "crop", q: 60 }
            )
            alt
          }
          wideImage: image {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "910", w: "1140", fit: "crop", q: 60 }
            )
            alt
          }
          id
          excerpt
        }
      }
    }
  `)

  const items = data.allDatoCmsPost.nodes
  const filters = []

  const allCategories = removeDuplicatesByTitle(
    items.flatMap(({ categories }) => categories)
  )

  allCategories.length > 1 &&
    filters.push({
      name: 'categories',
      label: 'Filter by Category',
      placeholder: {
        text: 'Show All'
      },
      options: [...configCategoriesForSelect(allCategories)]
    })

  const [queries, setQueries] = useState(defaultQueries)
  const [filteredItems, setFilteredItems] = useState([...items])
  const [visibleItems, setVisibleItems] = useState(17)

  const handleLoadMore = () =>
    setVisibleItems(prevVisibleItems => prevVisibleItems + 18)

  const selectRef = React.createRef()

  const onCategoryClick = e => {
    e.persist()
    e.preventDefault()

    selectRef.current.value = e.target.innerText
      .trim()
      .toLowerCase()
      .replace(/ /gi, '-')

    updateQueries({
      target: {
        value: selectRef.current.value,
        name: 'categories'
      }
    })
  }

  const filterItems = allItems => {
    return allItems.filter(item => {
      if (queries.size < 1) return true

      const filterFields = [...queries.keys()]

      for (let i = 0; i < filterFields.length; i++) {
        const type = filterFields[i]
        const itemValue = item[type]

        // If no value has been selected or default value has been selected
        if (!queries.has(type) || queries.get(type) === '') {
          continue
        }

        if (!itemValue) return false

        // If value check item
        const value = queries.get(type)

        // Value can be an array, test all values
        if (Array.isArray(itemValue)) {
          if (itemValue.find(({ slug }) => slug === value) === undefined)
            return false
        } else if (itemValue.slug && itemValue.slug !== value) {
          return false
        } else if (!itemValue.slug && itemValue !== value) {
          return false
        }
      }

      return true
    })
  }

  const filterContent = () => {
    const filteredList = filterItems(items)
    setFilteredItems(filteredList)
  }

  const updateQueries = ({ target }) => {
    const newQuery = new Map(queries)

    newQuery.set(target.name, target.value)
    setQueries(newQuery)
  }

  useEffect(() => {
    if (!queries) return

    filterContent()
  }, [queries])

  return (
    <Fragment>
      {filters.length > 0 && (
        <Block gutters>
          <form>
            <Filter
              onChange={updateQueries}
              filters={filters}
              ref={selectRef}
            />
          </form>
        </Block>
      )}
      {filteredItems.length > 0 ? (
        <Block gutters>
          <Grid columns={2}>
            {filteredItems.slice(0, visibleItems).map(item => {
              return (
                <Card
                  key={`card--${item.id}`}
                  elevated
                  contained
                  onClick={e => onCategoryClick(e)}
                  heading={item.title}
                  horizontalImage={item.image}
                  description={item.excerpt}
                  button={{
                    link: { path: item.path },
                    displayText: 'Read More'
                  }}
                  horizontal={true}
                  unstyledButton={true}
                  buttonStyle={'primary'}
                  subHeading={item.meta.firstPublishedAt}
                  categories={item.categories}
                />
              )
            })}
          </Grid>
        </Block>
      ) : (
        <Block padding="both">
          <center>
            {`Sorry, we can't find an exact match for your search. Please try
            again.`}
          </center>
        </Block>
      )}
      {filteredItems.length > visibleItems && (
        <Block gutters paddingTop="double" centreChildren>
          <Button
            type="button"
            onClick={handleLoadMore}
            children="Load More"
            fullWidthMobile
            block
            short
            alignment={'center'}
          />
        </Block>
      )}
    </Fragment>
  )
}

const removeDuplicatesByTitle = array =>
  Array.from(new Set(array.map(a => a.title))).map(title => {
    return array.find(a => a.title === title)
  })

const configCategoriesForSelect = items =>
  items.map(({ slug, title }) => ({
    value: slug,
    text: title
  }))

export default FilteredList

export const query = graphql`
  fragment FilteredBlogList on DatoCmsFilteredBlogList {
    paddingTop
    paddingBottom
    background
    id
    model {
      apiKey
    }
    __typename
  }
`
